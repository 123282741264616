
import React from 'react';
import "./App.css";

const App = () => {
    return (
      <div className="App">
        <header className="App-header">
          <h1>Rachel turned 30!</h1>
          <iframe src="https://giphy.com/embed/xUPOqo6E1XvWXwlCyQ" frameBorder="0" class="giphy-embed" allowFullScreen></iframe><p><a href="https://giphy.com/gifs/spacejam-space-jam-movie-xUPOqo6E1XvWXwlCyQ">via GIPHY</a></p>
        </header>
      </div>
    );
}

export default App;
